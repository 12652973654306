<template>

  <!--
  podaj regon
  lub uzupełnij ręcznie

 -->
  <b-sidebar
    id="sidebar-add-new-client"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Nowy klient
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- NIP -->
        <b-col>
          <b-input-group class="d-inline">
            <b-form-input placeholder="Podaj NIP" />
            <b-input-group-append>
              <b-button
                variant="primary"
                class="mt-1 mb-1"
                @click="customer.loading = !customer.loading"
              >
                Pobierz dane z GUS
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <!-- NIP -->

        <!-- LOADING... -->

        <div
          v-show="customer.loading"
          class="spinner-border spinner-border-sm ml-2"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>

        <!-- LOADING.. -->

        <!-- Nazwa firmy -->
        <b-form-group
          label="Nazwa firmy"
          label-for="company-name"
        >
          <b-form-input
            id="company-name"

            trim
            placeholder="Nazwa firmy"
          />
        </b-form-group>

        <!-- Ulica -->
        <b-form-group
          label="Ulica"
          label-for="company-street"
        >
          <b-form-input
            id="company-street"

            trim
            placeholder="Ulica"
          />
        </b-form-group>

        <!-- Kod pocztowy-->
        <b-form-group
          label="Kod pocztowy"
          label-for="company-zip-code"
        >
          <b-form-input
            id="company-zip-code"

            trim
            placeholder="Kod pocztowy"
          />
        </b-form-group>

        <!-- Miejscowość-->
        <b-form-group
          label="Miejscowość"
          label-for="company-city"
        >
          <b-form-input
            id="company-city"

            trim
            placeholder="Miejscowość"
          />
        </b-form-group>

        <!-- Państwo -->
        <b-form-group
          label="Państwo"
          label-for="country"
        >
          <v-select

            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

            :clearable="false"
            input-id="country"
          />
        </b-form-group>

        <b-form-checkbox
          v-model="customer.differentAddress"
          class="mt-1 mb-1"
          value="A"
        >
          Chce podać inny adres do korespondencji
        </b-form-checkbox>

        <b-row v-show="customer.differentAddress">

          <!-- Nazwa firmy -->
          <b-form-group
            label="Nazwa firmy"
            label-for="contact-company-name"
          >
            <b-form-input
              id="contact-company-name"

              trim
              placeholder="Nazwa firmy"
            />
          </b-form-group>

          <!-- Ulica -->
          <b-form-group
            label="Ulica"
            label-for="contact-street"
          >
            <b-form-input
              id="contact-street"

              trim
              placeholder="Ulica"
            />
          </b-form-group>

          <!-- Kod pocztowy-->
          <b-form-group
            label="Kod pocztowy"
            label-for="contact-zip-code"
          >
            <b-form-input
              id="contact-zip-code"

              trim
              placeholder="Kod pocztowy"
            />
          </b-form-group>

          <!-- Miejscowość-->
          <b-form-group
            label="Miejscowość"
            label-for="contact-city"
          >
            <b-form-input
              id="contact-city"

              trim
              placeholder="Miejscowość"
            />
          </b-form-group>

          <!-- Państwo -->
          <b-form-group
            label="Państwo"
            label-for="contact-country"
          >
            <v-select

              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

              :clearable="false"
              input-id="contact-country"
            />
          </b-form-group>

          <b-form-checkbox
            class="mt-1 mb-1"
            value="A"
          >
            Adres korespondencyjny jest taki sam jak firmowy
          </b-form-checkbox>
        </b-row>

        <!-- Numer telefonu -->
        <b-form-group
          label="Numer telefonu"
          label-for="contact-number"
        >
          <b-form-input
            id="contact-number"

            type="number"
            placeholder="763-242-9206"
            trim
          />
        </b-form-group>

        <!-- Adres email-->
        <b-form-group
          label="Adres email"
          label-for="contact-email"
        >
          <b-form-input
            id="contact-email"

            type="number"
            placeholder="adres email"
            trim
          />
        </b-form-group>

        <!-- Strona www-->
        <b-form-group
          label="Strona www"
          label-for="contact-www"
        >
          <b-form-input
            id="contact-www"

            type="number"
            placeholder="strona www"
            trim
          />
        </b-form-group>

        <!-- Form Actions1 -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Dodaj Klienta
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Anuluj
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox, BCol, BRow, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BButton, vSelect, BFormCheckbox, BCol, BRow, BInputGroup, BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  setup() {
    const customer = ref({
      name: '',
      email: '',
      address: '',
      country: '',
      contact: '',
      loading: false,
      differentAddress: true,
    })

    return {
      customer,
      countries,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
