<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search')"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              {{ data.item.Title }}
            </template>
          </b-media>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'web-app-client-preview', params: { id: data.item.id }})"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'
import Swal from 'sweetalert2'
// eslint-disable-next-line import/no-cycle
import useUsersList from '@/views/apps/user/users-list/useUsersList'

export default {
  name: 'WebAppClientList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    vSelect,
  },
  setup() {
    const {
      sortBy,
      isSortDirDesc,
      perPageOptions,
      perPage,
      searchQuery,
    } = useUsersList()
    return {
      sortBy,
      isSortDirDesc,
      perPageOptions,
      perPage,
      searchQuery,
    }
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'name',
          label: 'Nazwa',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'akcje',
          sortable: false,
        },
      ],
    }
  },
  mounted() {
    this.getClients()
  },
  methods: {
    getClients() {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Client`, config).then(response => {
        this.items = response.data
      }).catch(error => {
        console.log(error)
        Swal.fire('Nie można pobrać danych klientów', '', 'error')
      })
    },
  },
}
</script>
