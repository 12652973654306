import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  state: {
    // koszty
    costs: [
      {
        id: 40,
        issuedDate: '17 Jul 2019',
        client: {
          address: '04033 Wesley Wall Apt. 961',
          company: '3M LOGISTICS Sp. z o.o.',
          companyEmail: 'brenda49@taylor.info',
          country: 'Haiti',
          contact: '(226) 204-8287',
          name: 'Stephanie Burns',
        },
        service: 'UI/UX Design & Development',
        total: 5219,
        invoiceStatus: 'Expired',
        balance: 0,
        dueDate: '15 Dec 2019',
        paymentDetails: {
          paymentMethood: 'Bank Account',
          totalDue: '$12,110.55',
          bankName: 'American Bank',
          country: 'United States',
          iban: 'ETD95476213874685',
          swiftCode: 'BR91905',
        },

      },
    ],
    workers: [
      {
        id: 4988,
        issuedDate: '17 Jul 2019',
        client: {
          address: '04033 Wesley Wall Apt. 961',
          company: '3M LOGISTICS Sp. z o.o.',
          companyEmail: 'brenda49@taylor.info',
          country: 'Haiti',
          contact: '(226) 204-8287',
          name: 'Stephanie Burns',
        },
        service: 'UI/UX Design & Development',
        total: 5219,

        invoiceStatus: 'Expired',
        balance: 0,
        dueDate: '15 Dec 2019',
        paymentDetails: {
          paymentMethood: 'Bank Account',
        },

      },
    ],
  },
  strict: process.env.DEV,
})
