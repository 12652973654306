import guard from '@/router/routes/guard'

export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/workers/list',
    name: 'apps-workers-list',
    component: () => import('@/views/apps/worker/workers-list/WorkersList.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/workers/preview/:id',
    name: 'apps-workers-preview',
    component: () => import('@/views/apps/worker/single-worker/WorkerPreview.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  {
    // dodaj pracownika
    path: '/apps/workers/add',
    name: 'apps-workers-add',
    component: () => import('@/views/apps/worker/AddWorker.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  {
    // wyślij formularz w momencie dodawania pracownika
    path: '/apps/workers/send-form',
    name: 'apps-workers-send-form',
    component: () => import('@/views/apps/worker/SendForm.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/invoice/preview/:id/:type',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    beforeEnter: (to, from, next) => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData === null) {
        return next({ name: 'auth-login' })
      }
      if (userData.type !== 'Administrator' && userData.type !== 'Administrator-BR' && userData.type !== 'Administrator-Client') {
        return next({ name: 'misc-not-authorized' })
      }
      return next()
    },
  },
  {
    path: '/apps/invoice/add/:type',
    name: 'apps-invoice-add-type',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/invoice/edit/:id/:type',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/apps/invoice/add/advancepayment/:id',
    name: 'apps-invoice-edit-advancepayment',
    component: () => import('@/views/apps/invoice/invoice-types/AdvancePayment.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  // *===============================================---*
  // *--------- CONTRACTORS ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/contractors/list',
    name: 'apps-contractors-list',
    component: () => import('@/views/apps/user/users-list/ContractorsList.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  {
    path: '/apps/contractors/:id',
    name: 'apps-contractor-preview',
    component: () => import('@/views/apps/user/ContractorPreview.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  {
    path: '/apps/invoices/reports',
    name: 'invoice-reports',
    component: () => import('@/views/apps/invoice/invoice-reports/InvoiceReports.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  // *===============================================---*
  // *--------- SEGRETATOR---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/documents/costs',
    name: 'documents-costs',
    component: () => import('@/views/apps/invoice/invoice-list/Costs.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  {
    path: '/documents/costs/:id',
    name: 'apps-cost-preview',
    component: () => import('@/views/apps/costs/CostPreview.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    // segregator - dokumenty sprzedażowe == faktury
    path: '/documents/sale',
    name: 'documents-sale',
    // component: () => import('@/views/apps/invoice/invoice-list/Sale.vue'),
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  {
    path: '/documents/payroll',
    name: 'documents-payroll',
    component: () => import('@/views/apps/worker/workers-list/PayrollList.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  {
    path: '/documents/payroll/:id',
    name: 'single-payroll',
    component: () => import('@/views/apps/payroll/SinglePayroll.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  {
    path: '/documents/tax-returns',
    name: 'documents-tax-returns',
    component: () => import('@/views/apps/user/users-list/taxReturns.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  {
    path: '/documents/bank-statement',
    name: 'documents-bank-statement',
    component: () => import('@/views/apps/user/users-list/bankStatement.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  // *===============================================---*
  // *--------- RAPORTY---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/reports/invoices',
    name: 'reports-invoices',
    component: () => import('@/views/apps/invoice/invoice-reports/InvoiceReports.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  {
    path: '/reports/income',
    name: 'reports-income',
    component: () => import('@/views/apps/reports/ReportsIncome.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/reports/costs',
    name: 'reports-costs',
    component: () => import('@/views/apps/reports/ReportsCosts.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  // *===============================================---*
  // *--------- FAKTURY --> WINDYKACJA---- -------------*
  // *===============================================---*

  {
    path: '/apps/invoices/list/expired',
    name: 'apps-invoices-expired',
    component: () => import('@/views/apps/invoice/invoice-list/ExpiredInvoices.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  // *===============================================---*
  // *--------- UMOWY  ---- ----------------------------*
  // *===============================================---*

  {
    path: '/apps/contracts/add',
    name: 'apps-contracts-add',
    component: () => import('@/views/apps/contracts/add/AddContract.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },

  // *===============================================---*
  // *--------- SERWISANT ---- ----------------------------*
  // *===============================================---*
  {
    path: '/apps/servicers',
    name: 'apps-servicers',
    component: () => import('@/views/apps/servicers/Servicers.vue'),
  },
  {
    path: '/apps/servicer/:id',
    name: 'apps-servicer',
    component: () => import('@/views/apps/servicers/EditServicer.vue'),
  },
  {
    path: '/apps/servicer',
    name: 'apps-servicer',
    component: () => import('@/views/apps/servicers/EditServicer.vue'),
  },

  // *===============================================---*
  // *--------- PRZEDSTAWICIEL ---- ----------------------------*
  // *===============================================---*
  {
    path: '/apps/representatives',
    name: 'apps-representatives',
    component: () => import('@/views/apps/representatives/Representatives.vue'),
  },
  {
    path: '/apps/representative/:id',
    name: 'apps-representative',
    component: () => import('@/views/apps/representatives/EditRepresentative.vue'),
  },
  {
    path: '/apps/representative',
    name: 'apps-representative',
    component: () => import('@/views/apps/representatives/EditRepresentative.vue'),
  },

  // *===============================================---*
  // *--------- Historia Urządzenia --------------------*
  // *===============================================---*
  {
    path: '/apps/history',
    name: 'apps-history',
    component: () => import('@/views/apps/history/History.vue'),
  },

  // *===============================================---*
  // *--------- KLIENT ---- ----------------------------*
  // *===============================================---*
  {
    path: '/apps/clients',
    name: 'apps-clients',
    component: () => import('@/views/apps/clients/Clients.vue'),
  },
  {
    path: '/apps/client/:id',
    name: 'apps-client',
    component: () => import('@/views/apps/clients/EditClient.vue'),
  },
  {
    path: '/apps/client',
    name: 'apps-client',
    component: () => import('@/views/apps/clients/EditClient.vue'),
  },

  // *===============================================---*
  // *--------- SERWIS ---- ----------------------------*
  // *===============================================---*
  {
    path: '/apps/services',
    name: 'apps-services',
    component: () => import('@/views/apps/services/Services.vue'),
  },
  {
    path: '/apps/service/:id',
    name: 'apps-service',
    component: () => import('@/views/apps/services/EditService.vue'),
  },
  {
    path: '/apps/service',
    name: 'apps-service',
    component: () => import('@/views/apps/services/EditService.vue'),
  },

  // *===============================================---*
  // *--------- ZREALIZOWANY SERWIS ---- ----------------------------*
  // *===============================================---*
  {
    path: '/apps/realizedservices',
    name: 'apps-realizedservices',
    component: () => import('@/views/apps/realizedservices/RealizedServices.vue'),
  },
  {
    path: '/apps/realizedservice/:id',
    name: 'apps-realizedservice',
    component: () => import('@/views/apps/realizedservices/EditRealizedService.vue'),
  },
  {
    path: '/apps/realizedservice',
    name: 'apps-realizedservice-filter',
    component: () => import('@/views/apps/realizedservices/EditRealizedService.vue'),
  },

  // *===============================================---*
  // *--------- FILTR ---- ----------------------------*
  // *===============================================---*
  {
    path: '/apps/filters',
    name: 'apps-filters',
    component: () => import('@/views/apps/filters/Filters.vue'),
  },
  {
    path: '/apps/filter/:id',
    name: 'apps-filter',
    component: () => import('@/views/apps/filters/EditFilter.vue'),
  },
  {
    path: '/apps/filter',
    name: 'apps-filter-client',
    component: () => import('@/views/apps/filters/EditFilter.vue'),
  },

  // *===============================================---*
  // *--------- NAPRAWA ---- ----------------------------*
  // *===============================================---*
  {
    path: '/apps/reparatures',
    name: 'apps-reparatures',
    component: () => import('@/views/apps/reparatures/Reparatures.vue'),
  },
  {
    path: '/apps/reparatur/:id',
    name: 'apps-reparatur',
    component: () => import('@/views/apps/reparatures/EditReparatur.vue'),
  },
  {
    path: '/apps/reparatur',
    name: 'apps-reparatur-filter',
    component: () => import('@/views/apps/reparatures/EditReparatur.vue'),
  },

  // *===============================================---*
  // *--------- RAPORT ------------------------------------*
  // *===============================================---*
  {
    path: '/apps/report',
    name: 'apps-report',
    component: () => import('@/views/apps/reports/Report.vue'),
  },

  // *===============================================---*
  // *--------- ZMIANA HASŁA ---------------------------*
  // *===============================================---*
  {
    path: '/apps/changepassword',
    name: 'apps-changepassword',
    component: () => import('@/views/apps/settings/ChangePassword.vue'),
  },

  // *===============================================---*
  // *--------- UŻYTKOWNICY ---- ----------------------------*
  // *===============================================---*
  {
    path: '/apps/users',
    name: 'apps-users',
    component: () => import('@/views/apps/settings/Users.vue'),
  },
  {
    path: '/apps/user/:id',
    name: 'apps-user',
    component: () => import('@/views/apps/settings/EditUser.vue'),
  },
  {
    path: '/apps/user',
    name: 'apps-user',
    component: () => import('@/views/apps/settings/EditUser.vue'),
  },
]
