export default {
  userData: localStorage.getItem('userData'),
  protectAdmin(to, from, next) {
    if (localStorage.getItem('userRole') === null) {
      return next({ name: 'auth-login' })
    }
    if (localStorage.getItem('userRole') !== 'Administrator') {
      return next({ name: 'misc-not-authorized' })
    }
    return next()
  },
  protectTaxOfficeAdmin(to, from, next) {
    if (localStorage.getItem('userRole') === null) {
      return next({ name: 'auth-login' })
    }
    const userRole = localStorage.getItem('userRole')
    if (userRole !== 'Administrator' && userRole !== 'Administrator-BR') {
      return next({ name: 'misc-not-authorized' })
    }
    return next()
  },
  protectTaxOfficeClientAdmin(to, from, next) {
    if (localStorage.getItem('userRole') === null) {
      return next({ name: 'auth-login' })
    }
    const userRole = localStorage.getItem('userRole')
    if (userRole !== 'Administrator' && userRole !== 'Administrator-BR' && userRole !== 'Administrator-Client') {
      return next({ name: 'misc-not-authorized' })
    }
    return next()
  },
}
