<template>
  <b-card>
    <validation-observer
      ref="companyInfoForm"
      #default="{invalid}"
    >
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Nazwa"
              label-for="account-username"
            >
              <validation-provider
                #default="{ errors }"
                name="Nazwa"
                vid="company-name"
                rules="required"
              >
                <b-form-input
                  id="company-name"
                  v-model="currentCompany.Title"
                  placeholder="Nazwa"
                  :state="errors.length > 0 ? false:null"
                  name="username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="NIP"
              label-for="company-id"
            >
              <b-form-input
                v-model="currentCompany.NIP"
                name="company_id"
                placeholder="NIP"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="REGON"
              label-for="regon"
            >
              <validation-provider
                #default="{ errors }"
                name="REGON"
                vid="regon"
                rules="required"
              >
                <b-form-input
                  id="regon"
                  v-model="currentCompany.Regon"
                  name="regon"
                  :state="errors.length > 0 ? false:null"
                  placeholder="REGON"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Adres - województwo"
              label-for="register-state"
            >
              <validation-provider
                #default="{ errors }"
                name="Województwo"
                vid="state"
                rules="required"
              >
                <b-form-input
                  id="state"
                  v-model="currentCompany.Province"
                  name="register_state"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Adres - województwo"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Adres - powiat"
              label-for="register-district"
            >
              <b-form-input
                v-model="currentCompany.District"
                name="register_district"
                placeholder="Adres - powiat"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Adres - gmina"
              label-for="register-community"
            >
              <validation-provider
                #default="{ errors }"
                name="Gmina"
                vid="community"
                rules="required"
              >
                <b-form-input
                  id="community"
                  v-model="currentCompany.Community"
                  name="register_community"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Adres - gmina"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Adres - kod pocztowy"
              label-for="register-zip-code"
            >
              <validation-provider
                #default="{ errors }"
                name="Kod pocztowy"
                vid="zip-code"
                rules="required"
              >
                <b-form-input
                  id="zip-code"
                  v-model="currentCompany.ZipCode"
                  name="register_zip_code"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Adres - kod pocztowy"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Adres - miejscowość"
              label-for="register-city"
            >
              <validation-provider
                #default="{ errors }"
                name="Miejscowość"
                vid="city"
                rules="required"
              >
                <b-form-input
                  id="city"
                  v-model="currentCompany.City"
                  name="register_city"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Adres - miejscowość"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="updateCompanyData"
            >
              {{ $t('Save Changes') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
    }
  },
  computed: mapState({
    currentCompany: state => state.app.activeCompanyBusinessData,
  }),
  mounted() {
    this.getCompanyData()
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    getCompanyData() {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Business`, config).then(response => {
        if (this.$store.state.app.activeCompany === 'yourtax') {
          this.$store.commit('app/UPDATE_ACTIVE_COMPANY_BUSINESS_DATA', response.data[0])
        } else {
          this.$store.commit('app/UPDATE_ACTIVE_COMPANY_BUSINESS_DATA', response.data.filter(row => row.NIP === this.$store.state.app.activeCompany)[0])
        }
      }).catch(error => {
        console.log(error)
        Swal.fire('Coś poszło nie tak', '', 'error')
      })
    },
    updateCompanyData() {
      this.$refs.companyInfoForm.validate().then(success => {
        if (success) {
          const putData = {
            Title: this.currentCompany.Title,
            Regon: this.currentCompany.Regon,
            NIP: this.currentCompany.NIP,
            Province: this.currentCompany.Province,
            District: this.currentCompany.District,
            City: this.currentCompany.City,
            Street: this.currentCompany.Street,
            Community: this.currentCompany.Community,
            ZipCode: this.currentCompany.ZipCode,
            ContactProvince: this.currentCompany.Province,
            ContactDistrict: this.currentCompany.District,
            ContactCity: this.currentCompany.City,
            ContactStreet: this.currentCompany.Street,
            ContactCommunity: this.currentCompany.Community,
            ContactZipCode: this.currentCompany.ZipCode,
          }
          const token = localStorage.getItem('accessToken')
          const config = { headers: { Authorization: `Bearer ${token}` } }
          axios.put(`${process.env.VUE_APP_API_URL}Business`, putData, config).then(() => {
            Swal.fire('Zaktualizowano dane biura', '', 'success')
          }).catch(error => {
            console.log(error)
            Swal.fire('Coś poszło nie tak', '', 'error')
          })
        }
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
