// eslint-disable-next-line import/extensions
import AddNewUser from '@/views/apps/user/AddNewUser'
// eslint-disable-next-line import/extensions
import WebAppClientList from '@/views/apps/clients/WebAppClientList'
// eslint-disable-next-line import/extensions
import EditUser from '@/views/apps/user/EditUser'
import guard from '@/router/routes/guard'

export default [
  {
    path: '/user/add',
    name: 'user-add',
    component: AddNewUser,
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/user/edit/:id',
    name: 'user-edit',
    component: EditUser,
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/user/view/:id',
    name: 'user-view',
    component: () => import('@/views/apps/user/UserPreview.vue'),
    beforeEnter: (to, from, next) => {
      guard.protectTaxOfficeClientAdmin(to, from, next)
    },
  },
  {
    path: '/app-client',
    name: 'app-client-index',
    component: WebAppClientList,
    beforeEnter: (to, from, next) => {
      guard.protectAdmin(to, from, next)
    },
  },
]
