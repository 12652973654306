<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-card-header>
      <h2>
        Lista klientów biura rachunkowego
      </h2>
    </b-card-header>
    <b-card-body>
      <b-button
        variant="primary"
        :to="{ name: 'tax-office-client-add' }"
      >
        Dodaj klienta
      </b-button>
      <div class="mt-2">
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="clients"
          responsive
          :fields="fields"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                {{ data.item.Title }}
              </template>
            </b-media>
          </template>
          <template #cell(nip)="data">
            <b-media vertical-align="center">
              <template #aside>
                {{ data.item.NIP }}
              </template>
            </b-media>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1"
                @click="$router.push({ name: 'tax-office-client-preview', params: { id: data.item.id }})"
              />
            </div>
          </template>
        </b-table>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCardBody, BTable, BMedia,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useUsersList from '@/views/apps/user/users-list/useUsersList'
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
  name: 'TaxOfficeClientList',
  components: {
    BButton,
    BCard,
    BTable,
    BMedia,
    BCardHeader,
    BCardBody,
  },
  setup() {
    const {
      sortBy,
      isSortDirDesc,
    } = useUsersList()
    return {
      sortBy,
      isSortDirDesc,
    }
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'name',
          label: 'Nazwa',
          sortable: true,
        },
        {
          key: 'nip',
          label: 'NIP',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'akcje',
          sortable: false,
        },
      ],
    }
  },
  computed: mapState({
    clients: state => state.app.clients,
  }),
  mounted() {
    this.getClients()
  },
  methods: {
    getClients() {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Client`, config).then(response => {
        this.$store.commit('app/UPDATE_TAX_OFFICE_CLIENTS', response.data)
      }).catch(error => {
        console.log(error)
        Swal.fire('Nie można pobrać danych klientów', '', 'error')
      })
    },
  },
}
</script>
