export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    beforeEnter: (to, from, next) => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData === null) {
        return next({ name: 'auth-login' })
      }
      if (userData.type !== 'Administrator') {
        return next({ name: 'misc-not-authorized' })
      }
      return next()
    },
  },
]
